import React, { useEffect, useRef } from "react";
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";

const CustomeScrollbar = ({ children, height= 400, active= true }) => {

  if (!active) return children

  const scrollContainerRef = useRef(null);

  useEffect(() => {
    const ps = new PerfectScrollbar(scrollContainerRef.current, {
      wheelSpeed: 1,
      wheelPropagation: true,
      minScrollbarLength: 20,
      suppressScrollX: true
    });

    // Cleanup on component unmount
    return () => {
      ps.destroy();
    };
  }, []);

  return (
    <div
      ref={scrollContainerRef}
      style={{
        maxHeight: height, // Adjust as needed
        overflow: "hidden",  // Hide native scrollbar
        position: "relative",
      }}
    >
      {children}
    </div>
  );
};

export default CustomeScrollbar;
