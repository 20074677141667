import React, { Fragment, useEffect, useState } from "react";
import moment from "moment";
import { FormattedMessage } from "react-intl";
import { Link, useParams } from "react-router-dom";
import { Card, CardBody, Col, Row, Spinner } from "reactstrap";

import SubmissionReview from "../../components/SubmissionReview";
import { IntlService, TaskService } from "../../wasfaty/services";
import "./style.scss";

function ScheduleMedicineAvailabilityDetails({ item, modalData }) {

  if (!item) return null;
  return (
    <>
      {/* {!item && task_id && <SubmissionReview task={item.task} />} */}

      <Row id="AuditDetails">
        <Col>
          {!item && (
            <div
              style={{
                position: "absolute",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                zIndex: 10,
                width: "100%",
              }}
            >
              <Spinner
                color="primary"
                style={{
                  height: 50,
                  width: 50,
                }}
              />
            </div>
          )}

          <nav
            className="navbar navbar-expand-md mb-1 py-1"
            style={{ backgroundColor: "#fff" }}
          >
            <div className="container-fluid">
              <Link
                className="navbar-brand"
                to="#"
                style={{ color: "#525866", fontWeight: "500" }}
              >
                {IntlService.m(item ? "Review " : " Details")}
              </Link>
            </div>
          </nav>
          {item && (
            <>
              <Card style={{ borderRadius: "0px", marginBottom: "5px" }}>
                <CardBody>
                  <Row>
                    <Col md="4" sm="12">
                      <p>
                        <FormattedMessage
                          id="Region"
                          defaultMessage={"Region"}
                        />
                      </p>
                      <h4>{item?.region.label}</h4>
                    </Col>
                    <Col md="4" sm="12">
                      <p>
                        <FormattedMessage
                          id="Cluster"
                          defaultMessage={"Cluster"}
                        />
                      </p>
                      <h4>{item?.cluster?.label || "N/A"}</h4>
                    </Col>
                    <Col md="4" sm="12">
                      <p>
                        <FormattedMessage
                          id="Supervisor"
                          defaultMessage={"Supervisor"}
                        />
                      </p>
                      <h4>{item?.supervisor.label}</h4>
                    </Col>
                    <Col md="4" sm="12">
                      <p>
                        <FormattedMessage
                          id="Due Date"
                          defaultMessage={"Due Date"}
                        />
                      </p>
                      <h4>{moment(item?.dueDate).format("YYYY-MM-DD")}</h4>
                    </Col>
                  </Row>
                  {
                    !!item?.tasks?.length && (
                      <Fragment>
                        <hr/>
                        <Row>
                          <Col md="4" sm="12">
                            <p>
                              <FormattedMessage
                                id="Sites"
                                defaultMessage={"Sites"}
                              />
                            </p>
                          </Col>
                          <Col md="4" sm="12">
                            <p>
                              <FormattedMessage
                                id="Inspectors"
                                defaultMessage={"Inspectors"}
                              />
                            </p>
                          </Col>
                        </Row>
                        {
                          item?.tasks?.map(task => (
                            <Row className="mb-1">
                              <Col md="4" sm="12">
                                <h4>{task?.site.label}</h4>
                              </Col>
                              <Col md="4" sm="12">
                                <h4>{task?.inspector?.label || "N/A"}</h4>
                              </Col>
                            </Row>
                          ))
                        }
                      </Fragment>
                    )
                  }
                  {
                    !!modalData?.length && (
                      <Fragment>
                        <hr/>
                        <Row>
                          <Col md="4" sm="12">
                            <p>
                              <FormattedMessage
                                id="medicines"
                                defaultMessage={"Medicines"}
                              />
                            </p>
                          </Col>
                        </Row>
                        {
                          modalData?.map((item, index) => (
                            <Row className="mb-1">
                              <Col md="4" sm="12" className="d-flex">
                                <h4 style={{marginRight: 20}}>{`${index+1})`}</h4>
                                <h4>{item.name}</h4>
                              </Col>
                            </Row>
                          ))
                        }
                      </Fragment>
                    )
                  }
                </CardBody>
              </Card>
            </>
          )}
        </Col>
      </Row>
    </>
  );
}

export default ScheduleMedicineAvailabilityDetails;
