import { Tooltip } from "antd";
import React, { Fragment, useEffect, useState } from "react";
import { Card, Col, Row } from "reactstrap";

import {
  getRiskLevelStyle,
  SitesDataSkeleton,
} from "../RegionalDashboardHelper";
import { SC } from "../../../../wasfaty/Api/serverCall";
import CustomeScrollbar from "../../../../../@core/components/CustomeScrollbar";
import { IntlService } from "../../../../wasfaty/services";

const CardsGroup = ({ cardsData, getDashboardData, getFilterParams }) => {
  const [sites, setSites] = useState(null);
  const [notComplyStandards, setNotComplyStandards] = useState(null);
  const [commonNotes, setCommonNotes] = useState(null);
  const [siteId, setSiteId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isReset, setIsReset] = useState(false);

  useEffect(() => {
    const notComplyStandards = cardsData?.notComplyStandards?.map((item) => ({
      ...item,
      ...getRiskLevelStyle(item.risk_level),
    }));

    setSites(cardsData?.sites);
    setCommonNotes(cardsData?.commonNotes);
    setNotComplyStandards(notComplyStandards);
  }, [cardsData]);

  const getDataBySite = (site_id) => {
    setLoading(true);
    setIsReset(true);
    setSiteId(site_id);
    setCommonNotes(null);
    setNotComplyStandards(null);

    SC.getCall({
      url: `dashboard/compliance-site`,
      params:  { site_id, ...getFilterParams()},
    }).then((res) => {
      if (res?.data) {
        const notComplyStandards = res?.data?.notComplyStandards?.map(
          (item) => ({
            ...item,
            ...getRiskLevelStyle(item.risk_level),
          })
        );

        setCommonNotes(res?.data?.commonNotes);
        setNotComplyStandards(notComplyStandards);
      }
      setLoading(false);
    });
  };

  const DataRow = ({
    tooltipTitle,
    value,
    index,
    style = {},
    onClick = () => {},
  }) => (
    <Fragment>
      <div onClick={onClick} className={`row_item`} style={style}>
        <Tooltip
          overlayClassName="custom-tooltip"
          overlayInnerStyle={{ backgroundColor: "#FFF", color: "#0A0D14" }}
          placement="bottom"
          title={tooltipTitle}
        >
          <div className="row_val">{tooltipTitle}</div>
        </Tooltip>
        <div className="text">{value}</div>
      </div>
      {index <= 8 && <div className="h_row" />}
    </Fragment>
  );

  const RiskRow = ({ item, index }) => {
    const { textColor, bgColor, icon } = getRiskLevelStyle(item.risk_level);

    return (
      <Fragment>
        <div className="row_item">
          <Tooltip
            overlayClassName="custom-tooltip"
            overlayInnerStyle={{ backgroundColor: "#FFF", color: "#0A0D14" }}
            placement="bottom"
            title={item.title}
          >
            <div className="row_val">{item.title}</div>
          </Tooltip>
          <div className="risk_level" style={{ backgroundColor: bgColor }}>
            <div className="icon">{icon}</div>
            <div className="text" style={{ color: textColor }}>
              {item.risk_level}
            </div>
          </div>
        </div>
        {index < notComplyStandards.length - 1 && <div className="h_row" />}
      </Fragment>
    );
  };

  return (
    <Row className="gy-1 gx-1 cards_group">
      <Col>
        <Card className="sites_card">
          <div className="sub_header">
            <div className="title">{IntlService.m("Sites")}</div>
            {isReset && (
              <div
                onClick={() => {
                  getDashboardData();
                  setIsReset(false);
                }}
                className="reset_button"
              >
                {IntlService.m("Clear")}
              </div>
            )}
          </div>
          <div className="sub_title">
            <div className="col_name">{IntlService.m("Sites")}</div>
            <div className="col_name">{IntlService.m("Percentage")}</div>
          </div>
          <div className="row">
            {sites?.map((item, index) => (
              <DataRow
                onClick={() => getDataBySite(item.site_id)}
                key={index}
                index={index}
                style={item.site_id === siteId ? { background: "#e2e4e9" } : {}}
                tooltipTitle={item.site_name_ar || item.site_name}
                value={`${item.percentage}%`}
              />
            ))}
          </div>
        </Card>
      </Col>

      <Col>
        <Card className="standerds_card">
          <div className="title">{IntlService.m("Not Comply Standards")}</div>
          <div className="sub_title">
            <div className="col_name">{IntlService.m("Standard")}</div>
            <div className="col_name">{IntlService.m("Risk Level")}</div>
          </div>
          <CustomeScrollbar active={notComplyStandards?.length > 10} height={550}>
            <div className="row">
              {loading ? (
                <SitesDataSkeleton />
              ) : (
                <Fragment>
                  {!!notComplyStandards?.length ? (
                    notComplyStandards?.map((item, index) => (
                      <RiskRow key={index} index={index} item={item} />
                    ))
                  ) : (
                    <div className="not_found">{IntlService.m("Data not fount")}</div>
                  )}
                </Fragment>
              )}
            </div>
          </CustomeScrollbar>
        </Card>
      </Col>

      <Col>
        <Card className="common_notes_card">
          <div className="title">{IntlService.m("Common notes")}</div>
          <div className="sub_title">
            <div className="col_name">{IntlService.m("Not comply Standard")}</div>
            <div className="col_name">{IntlService.m("No. of repetition")}</div>
          </div>
          <div className="row">
            {loading ? (
              <SitesDataSkeleton />
            ) : (
              <Fragment>
                {!!commonNotes?.length ? (
                  commonNotes?.map((item, index) => (
                    <DataRow
                      key={index}
                      index={index}
                      site_id={item.site_id}
                      tooltipTitle={item.title}
                      value={item.count}
                    />
                  ))
                ) : (
                  <div className="not_found">{IntlService.m("Data not fount")}</div>
                )}
              </Fragment>
            )}
          </div>
        </Card>
      </Col>
    </Row>
  );
};

export default CardsGroup;
