import { Resources } from "./Resource";
import { SC } from "../api/serverCall";

class Service extends Resources {
  SC = SC;
  route = "form";
  routes = {
    find: "",
    create: "/create",
    show: "/show",
    update: "/update",
    delete: "/delete",
    allForms: this.route + "/all-forms",
  };

  constructor() {
    super(arguments);
  }

  getAllForms() {
    return SC.getCall({ url: this.routes.allForms });
  }

  getTask = (id, apiUrl = 'tasks') => {
    return SC.getCall({ url: `${apiUrl}/show/` + id });
  };

  checkSiteSubmission = async (site_id, form_id) => {
    let res = await SC.getCall({
      url: "form/check-site-submissions",
      params: {
        site_id,
        form_id,
      },
    });

    if (res.data.status === false) {
      return res.data.data;
    }

    return false;
  };
}

const FormService = new Service();

export default FormService;
