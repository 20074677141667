import { Fragment, useEffect, useRef, useState } from "react";
import { Row, Col, Spinner } from "reactstrap";
import List from "../components/listing/List";
import get from "lodash/get";

import moment from "moment";
import "./style.scss";
import toast from "react-hot-toast";
import { MoreVertical } from "react-feather";
import { useNavigate, useParams } from "react-router-dom";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
const MySwal = withReactContent(Swal);
import { getUserData } from "@utils";
import ListHeader from "../components/ListHeader";
import { observer } from "mobx-react";
import FormService from "../services/FormService";
import FormSubmissionService from "../services/FormSubmissionService";
import Dropdown from "../components/Dropdown";
import DateObject from "react-date-object";
import arabic from "react-date-object/calendars/arabic";
import arabic_ar from "react-date-object/locales/arabic_en";
import ExportExcelComponent from "../components/ExportExcel";
import pdfIcon from "../assets/pdfIcon.svg";

import { Download } from "react-feather";
import AuthUser from "../../wasfaty/services/AuthService";

const displayExtras = process.env.REACT_APP_FM_COLUMNS;

function extractFieldsInfo(properties, parentPath = "") {
  let fieldsInfo = [];

  for (const key in properties) {
    const propertyPath = parentPath ? `${parentPath}.${key}` : key;
    const property = properties[key];

    if (property.tableField === true) {
      const fieldInfo = {
        key,
        title: property.title || key,
        xComponent: property["x-component"] || null,
      };

      fieldsInfo.push(fieldInfo);
    }

    if (property.properties) {
      fieldsInfo = fieldsInfo.concat(
        extractFieldsInfo(property.properties, propertyPath)
      );
    }
  }

  return fieldsInfo;
}

function getFieldsInfo(id) {
  const storedData = localStorage.getItem(id + "fieldsInfo");

  try {
    if (storedData) {
      const { data, expiry } = JSON.parse(storedData);
      const currentTime = new Date().getTime();

      if (currentTime < expiry) {
        // Data is not expired, return it
        return data;
      } else {
        // Data is expired, remove it from localStorage
        localStorage.removeItem(id + "fieldsInfo");
      }
    }
  } catch (error) {}

  // Return null if no valid data is found or if data is expired
  return null;
}

function getFormInfo(id) {
  const storedData = localStorage.getItem(id + "formInfo");
  if (storedData) {
    try {
      return JSON.parse(storedData);
    } catch (error) {}
  }

  return null;
}

const FormBuilderFormList = observer(() => {
  const navigate = useNavigate();

  const { id } = useParams();
  const [selectedRow, setSelectedRow] = useState();

  const user = getUserData();
  const type = user.type?.toLowerCase();
  const userId = user._id;
  const canEditDelete = ["admin"].includes(type);
  const canView = ["moderator", "cluster manager"].includes(type);
  const [form, setForm] = useState(() => getFormInfo(id) || null);
  const [tableField, setTableFields] = useState(() => getFieldsInfo(id) || []);

  const [items, setItems] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const [pagination, setpagination] = useState({
    page: 0,
    pageCount: 0,
    perPage: 10,
    to: 0,
    totalPages: 0,
  });

  const [filter, setFilter] = useState({
    search: "",
    fromDate: "",
    toDate: "",
  });
  const filterRef = useRef();
  filterRef.current = filter;

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setTableFields([]);
    setItems([]);
    getForm();
  }, [id]);

  const getForm = () => {
    let cachedFieldsInfo = getFieldsInfo(id);
    let formInfo = getFormInfo(id);
    setForm(formInfo);

    if (cachedFieldsInfo?.length && formInfo) {
      setTableFields(cachedFieldsInfo);
    } else {
      FormService.show(id).then((res) => {
        const extractedFieldsInfo = extractFieldsInfo(
          res.data?.data?.schema?.schema?.properties
        );
        const expiryTime = new Date().getTime() + 1 * 60 * 60 * 1000; // One day expiry
        localStorage.setItem(
          id + "fieldsInfo",
          JSON.stringify({ data: extractedFieldsInfo, expiry: expiryTime })
        );
        setTableFields(extractedFieldsInfo);
        setForm(res.data?.data);
        localStorage.setItem(id + "formInfo", JSON.stringify(res.data?.data));
      });
    }
  };

  const getAllItems = (query) => {
    setIsLoading(true);
    let formInfo = getFormInfo(id);

    setLoading(true);

    const columns = getFieldsInfo(id)?.map?.(({ key }) => "data." + key) || [];
    const params = {
      page: (query?.selected || 0) + 1,
      per_page: query?.perPage || 10,
      id,
      //filters
      ...filterRef.current,
      columns: [...columns],
    };

    FormSubmissionService.find(params, formInfo?.meta_data?.route)
      .then((res) => {
        if (res?.data?.data) {
          setItems(
            res.data.data.data.map((item) => {
              item.site =
                (item.site?._id ? item.site : item.obligation_site) || {};

              delete item.obligation_site;
              return item;
            })
          );
          const _pagination = res.data.data;
          const page = _pagination.current_page;
          const perPage = _pagination.per_page;
          const totalPages = _pagination.total;
          const pageCount = Math.ceil(totalPages / perPage);

          const to = _pagination.to;

          setpagination({
            page,
            pageCount,
            perPage,
            totalPages,
            to,
          });
          setLoading(false);
          setIsLoading(false);
        }
      })
      .catch(() => {
        setLoading(false);
        setIsLoading(false);
      });
  };

  const showData = (form_id) => {
    navigate(`${form_id}`);
  };

  const DeleteData = (id) => {
    MySwal.fire({
      html: "<p class='confirm-class-head' >Are you sure you want to delete?</p>",
      showCancelButton: true,
      focusConfirm: false,
      confirmButtonText: "Ok",
      cancelButtonText: "Cancel",
      customClass: {
        confirmButton: "btn btn-primary",
        cancelButton: "btn btn-outline-danger ms-1",
      },
      buttonsStyling: false,
    }).then(({ isConfirmed }) => {
      if (isConfirmed) {
        let formInfo = getFormInfo(id);
        FormSubmissionService.delete(id, formInfo?.meta_data?.route).then(
          (res) => {
            if (res.status === 200) {
              getAllItems({
                selected: pagination.page - 1,
              });
              toast.success(res.data.data);
            } else {
              if (res.errors) {
                toast.error(error);
              }
            }
          }
        );
      }
    });
  };

  const columns = form?.meta_data?.columns;
  const columnsFromMeta = (columns ? columns.split(",") : []).map((col) => {
    return {
      title: col.split(":")[0],
      customAttribute: col.split(":")[1],
    };
  });

  const dynamicColumns = [...columnsFromMeta, ...tableField].map((field) => {
    return {
      name: field.title,
      cell: (row) => {
        let record = get(
          row,
          field.customAttribute
            ? `${field?.customAttribute}`
            : `data.${field.key}`
        );

        if (field.xComponent === "DatePicker" && moment(record)?._isValid) {
          return (
            <span>
              {moment(record).format("DD-MM-YYYY")}{" "}
              <span style={{ fontWeight: "bold" }}>
                {moment(record).format("h:mm")}
              </span>
            </span>
          );
        }

        if (field.xComponent === "DatePickerHijri" && record) {
          let date = new DateObject({
            date: record,
            calendar: arabic,
            locale: arabic_ar,
          });

          return <span>{date.format("YYYY-MM-DD")} </span>;
        }

        return <span>{(record?.label || record || "N/A") + ""}</span>;
      },
    };
  });

  const basicColumns = [
    ...dynamicColumns,

    {
      name: "Submitted by",
      sortable: true,
      cell: (row) => <span>{get(row, "user.name", "N/A")}</span>,
    },
    {
      name: "Date of Report",
      disableStartCase: true,
      sortable: true,
      cell: (row) => (
        <span>
          {moment(row.created_at).format("DD-MM-YYYY")}{" "}
          <span style={{ fontWeight: "bold" }}>
            {moment(row.created_at).format("h:mm")}
          </span>
        </span>
      ),
    },

    {
      name: "Inspection Type",
      omit:
        !displayExtras ||
        AuthUser.user.type === "facility" ||
        columnsFromMeta?.length,

      width: 300,
      cell: (row) => (
        <span>
          {row?.user?.type == "facility" ? "Self assessment" : "Inspection"}
        </span>
      ),
    },
    {
      name: "PDF",
      sortable: false,
      omit:
        !displayExtras ||
        AuthUser.user.type === "facility" ||
        columnsFromMeta?.length,
      cell: (row) => <PdfDownload row={row} form={form} />,
    },

    // {
    //   name: "Excel",
    //   sortable: false,
    //   omit:
    //     !displayExtras ||
    //     AuthUser.user.type === "facility" ||
    //     columnsFromMeta?.length,
    //   cell: (row) => (
    //     <ExportExcelComponent
    //       exportName="Excel Export"
    //       form_id={id}
    //       submissionId={row._id}
    //     />
    //   ),
    // },
    {
      name: "ACTION",
      sortable: false,
      // omit: !displayExtras || AuthUser.user.type === "facility",

      cell: (row) => (
        <>
          {
            <MoreVertical
              style={{ marginLeft: 10, cursor: "pointer" }}
              onClick={(e) => {
                setSelectedRow({
                  ...row,
                  clientX: e.clientX,
                  clientY: e.clientY,
                });
              }}
            />
          }
        </>
      ),
    },
  ];

  if (!tableField?.length && !form) return null;
  console.log(AuthUser.isFacilityManager, "----");

  return (
    <Fragment>
      {!!selectedRow && (
        <Dropdown
          size={20}
          row={selectedRow}
          DeleteData={DeleteData}
          showData={showData}
          isEdit={
            AuthUser.isAdmin ||
            AuthUser.isRegionalRepresentatives ||
            AuthUser.isSupervisor
          }
          isDelete={canEditDelete}
          onEdit={(form_id) => navigate(`${form_id}/Edit`)}
          onClose={() => setSelectedRow(null)}
        />
      )}
      <ListHeader
        totalHeader="Total"
        pagination={pagination}
        addButtonText="Create"
        getData={getAllItems}
        id={id}
        loading={loading}
        addFormUrl={
          !AuthUser.isFacilityManager && !AuthUser.isFacility ? `Add` : ""
        }
        filter={true}
        setFilter={(f) => {
          filterRef.current = f;
          setFilter(f);
          getAllItems();
        }}
        // dynamicDashboardUrl={`/${id}/Form-Builder/dashboard`}
        // exportComponent={
        // <ExportExcelComponent
        //   // url={props.exportUrl}
        //   exportName="Export"
        //   form_id={id}
        //   // params={props?.exportParams}
        // />
        // }
        exportComponent={
          <ExportExcelComponent
            // url={props.exportUrl}
            exportName="Export"
            form_id={id}
            // params={props?.exportParams}
          />
        }
      />
      <Row style={{ overFlow: "auto" }} striped>
        <Col sm="12">
          <List
            basicColumns={basicColumns}
            Mock={items}
            pagination={pagination}
            handlePageChange={getAllItems}
            isLoading={isLoading}
            isStopPaginationFirstCall={true}
          />
        </Col>
      </Row>
    </Fragment>
  );
});

export default FormBuilderFormList;

const PdfDownload = ({ row, form }) => {
  const [PDF, setPDF] = useState(false);
  let token = JSON.parse(localStorage.getItem("accessToken"));
  const baseUrl = process.env.REACT_APP_BASEURL;
  let siteName = row?.data?.site?.label.split?.("-")?.pop?.();
  let inspectionType =
    row?.user?.type == "facility" ? "Self assessment" : "Inspection";

  const pdfName = siteName
    ? "Report# (" +
      row?.report_no +
      ") " +
      siteName +
      " - " +
      moment(row.created_at).format("YYYY-MM-DD") +
      " - " +
      inspectionType
    : "Report# (" +
      row?.report_no +
      ") " +
      moment(row.created_at).format("YYYY-MM-DD") +
      " - " +
      inspectionType;

  return (
    <div className="cursorPointer">
      {PDF ? (
        <Spinner className="me-25" size="md" />
      ) : (
        <Download
          src={pdfIcon}
          style={{ cursor: "pointer" }}
          onClick={() => {
            setPDF(true);

            const myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");

            fetch(process.env.REACT_APP_PDF + "/pdf", {
              headers: myHeaders,

              method: "POST",
              body: JSON.stringify({
                url: `?id=${row._id}&token=${token}&url=${baseUrl}&project=Aman`,
                project: "Aman",
                inspectionType,
                submisstion: row,
                form: {
                  name: form?.name,
                },
              }),
            })
              .then((response) => {
                return response.blob();
              })
              .then((res) => {
                setPDF(false);
                try {
                  const link = document.createElement("a");
                  link.href = URL.createObjectURL(res);
                  link.download = pdfName;
                  link.click();
                  setPDF(false);
                } catch (error) {
                  setPDF(false);
                  // toast.error(IntlService.m("There is a problem with your network connection!"));
                }
              });
          }}
        />
      )}
    </div>
  );
};
