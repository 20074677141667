import React, { Fragment, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import Select from "react-select";
import { observer } from "mobx-react";
import {
  Row,
  Col,
  Label,
  Input,
  Button,
  Card,
  CardBody,
  FormFeedback,
  Spinner,
} from "reactstrap";
import toast from "react-hot-toast";
import Flatpickr from "react-flatpickr";
import { useNavigate, useParams } from "react-router-dom";

import { selectThemeColors } from "../../../../utility/Utils";
import ScheduleSelfAssessmentDetails from "../../Show/ScheduleSelfAssessment";
import {
  CalendarIcon,
  ChevronLeftGreen,
  ChevronLeftWhite,
  ChevronRightWhite,
  CircleWithPlusIcon,
  SmallCrossIocn,
} from "../../../../assets/wasfaty/SVG";
import { RegionService } from "../../../wasfaty/services";
import { SecduleSelfAssessmentModal } from "../../../wasfaty/Models/ScheduleSelfAssessmentModal";
import ScheduleSelfAssessmentServices from "../../../wasfaty/services/ScheduleSelfAssessmentServices";
import SitesOptionsModel from "../../../../@core/components/schedule-self-assessment/siteOptionsModal";

import "@styles/react/libs/flatpickr/flatpickr.scss";
import "./style.scss";

const ScheduleSelfAssessment = observer(() => {
  const navigate = useNavigate();
  const [item, setItem] = useState();
  const [allDay, setAllDay] = useState(false);
  const [regions, setRegions] = useState([]);
  const [modal, setModal] = useState(false);

  const intl = useIntl();
  const { id } = useParams();

  useEffect(() => {
    init();
  }, [id]);

  useEffect(() => {
    getRegions();
  }, []);

  const getRegions = () => {
    RegionService.getByMethod("Pharmacy").then(setRegions);
  };

  const init = async () => {
    let _item = new SecduleSelfAssessmentModal();
    setItem(_item);
  };

  const getTranslation = (text) => {
    return intl.formatMessage({
      id: text,
      defaultMessage: text,
    });
  };

  const handleChange = (name, value) => {
    item.setAttribute(name, value);
  };

  const ValidationHandler = observer(
    ({ isValid, message = `"This field is required!"` }) => {
      return (
        <>
          <Input type="text" hidden invalid={!isValid && item.isValidation} />
          <FormFeedback>
            <FormattedMessage id={message} defaultMessage={message} />
          </FormFeedback>
        </>
      );
    }
  );

  const submit = async (e) => {
    e.preventDefault();
    let isValid = await item.isValid();

    if (isValid) {
      item.setIsSubmit(true);
      let payload = item.getPayload();
      let promise = null;
      if (item.isEdit) {
        promise = ScheduleSelfAssessmentServices.update(item._id, payload);
      } else {
        promise = ScheduleSelfAssessmentServices.create(payload);
      }
      promise
        .then(async (res) => {
          if (res.status === 200 && res?.data?.status) {
            toast.success(res.data?.message, {
              duration: 4000,
            });
            navigate("/Schedule-Self-Assessment");
          } else {
            item.setIsSubmit(false);
            toast.error(res.data?.message);
          }
        })
        .catch((error) => {
          let e = error.response?.data?.errors;
          if (e && typeof e === "object") {
            Object.entries(e).forEach(([key, value]) => toast.error(value));
          } else {
            toast.error("Something went wrong!");
          }

          item.setIsSubmit(false);
        });

      return;
    }
    item.setValidation(true);
  };

  const Submit = observer(() => {
    return (
      <div className="d-flex justify-content-end">
        <Button.Ripple
          className="round border-none "
          color="primary"
          type="submit"
          onClick={submit}
          disabled={item.isSubmit}
        >
          {item.isSubmit && (
            <Spinner
              as="span"
              animation="grow"
              size="sm"
              role="status"
              aria-hidden="true"
            />
          )}
          <span style={{ color: "white" }}>
            {item.isEdit ? (
              <FormattedMessage id="Update" defaultMessage="Update" />
            ) : (
              <FormattedMessage id="Submit" defaultMessage="Submit" />
            )}
          </span>
        </Button.Ripple>
      </div>
    );
  });

  const filterOptions = (items, id, name, apiCallingFun) => {
    const filteredOptions = items.filter((obj) => obj._id !== id);
    item.setAttribute([name], filteredOptions);
    if (!!apiCallingFun) item[apiCallingFun]();
  };

  const toggleModal = () => setModal(!modal);

  if (!item) return null;
  return (
    <Fragment>
      {item.isReview ? (
        <ScheduleSelfAssessmentDetails item={item} />
      ) : (
        <Card className="p-2 schedule_self_ass">
          <Row className="">
            <Col className="" md="12" sm="12">
              <Label className="form_label">
                <FormattedMessage
                  id="select_form"
                  defaultMessage={"Select Form"}
                />
              </Label>
              <Select
                name="form_name"
                value={item.form_name}
                theme={selectThemeColors}
                className="react-select"
                classNamePrefix="select"
                placeholder={intl.formatMessage({
                  id: "Select",
                  defaultMessage: "Select",
                })}
                onChange={(form) => {
                  item.setAttribute("form_name", form);
                  item.setAttribute("region", null);
                  item.setAttribute("cluster", null);
                  item.setAttribute("site", null);
                }}
                options={item?.dynamicForms?.map((item) => {
                  return {
                    ...item,
                    label: getTranslation(item.name || item.name_ar),
                  };
                })}
              />
              <ValidationHandler isValid={item.form_name} />
            </Col>{" "}
            <Col className="dates mb-1" md="6" sm="12">
              <Label className="form_label" for="start_date">
                Start Date
              </Label>
              <Flatpickr
                required
                placeholder="DD / MM /YYYY"
                id="start_date"
                name="start_date"
                className="form-control"
                onChange={(date) => item.setAttribute("start_date", date[0])}
                value={item.start_date}
                options={{
                  enableTime: allDay === false,
                  dateFormat: "d/m/y",
                  maxDate: item.end_date,
                }}
              />
              <span className="calander_icon">{CalendarIcon}</span>
              <ValidationHandler isValid={item.start_date} />
            </Col>{" "}
            <Col
              className="dates mb-1"
              style={{ position: "relative" }}
              md="6"
              sm="12"
            >
              <Label className="form_label" for="end_date">
                End Date
              </Label>
              <Flatpickr
                required
                placeholder="DD / MM /YYYY"
                id="end_date"
                name="end_date"
                className="form-control"
                onChange={(date) => item.setAttribute("end_date", date[0])}
                value={item.end_date}
                options={{
                  enableTime: allDay === false,
                  dateFormat: "d/m/y",
                  minDate: item.start_date,
                }}
              />
              <span className="calander_icon">{CalendarIcon}</span>
              <ValidationHandler isValid={item.end_date} />
            </Col>{" "}
            <Col className="mb-1" md="12" sm="12">
              <Label className="form_label">
                <FormattedMessage id="Regions" defaultMessage={"Regions"} />
              </Label>
              <Select
                isMulti
                name="region"
                value={item.region}
                controlShouldRenderValue={false}
                theme={selectThemeColors}
                className="react-select"
                classNamePrefix="select"
                placeholder={intl.formatMessage({
                  id: "Add regions...",
                  defaultMessage: "Add regions...",
                })}
                onChange={(e) => {
                  item.setAttribute("region", e);
                  item.getClusters();
                  item.getAvailableSites()
                }}
                options={regions.map((item) => {
                  return {
                    ...item,
                    label: getTranslation(item?.name),
                  };
                })}
                isDisabled={
                  !item?.form_name?.name || !item.start_date || !item.end_date
                }
              />
              {!!item?.region?.length && (
                <Row
                  className="gap-1"
                  style={{ marginInline: 1, marginTop: 10 }}
                >
                  {item?.region?.map((region) => (
                    <Col className="selected_options col-auto" key={region._id}>
                      <div className="text">{getTranslation(region.name)}</div>
                      <div
                        className="cross"
                        onClick={() => {
                          item.setAttribute("cluster", null);
                          item.setAttribute("site", null);
                          filterOptions(
                            item.region,
                            region._id,
                            "region",
                            "getClusters"
                          );
                          item.getAvailableSites()
                        }}
                      >
                        {SmallCrossIocn}
                      </div>
                    </Col>
                  ))}
                </Row>
              )}
              <ValidationHandler isValid={item.region?.length} />
            </Col>{" "}
          </Row>
          <Row className="">
            <Col className="mt-1" md="6" sm="12">
              <Label className="form_label">
                <FormattedMessage
                  id="cluster_name"
                  defaultMessage={"Cluster Name"}
                />
              </Label>
              <Select
                isMulti
                name="cluster"
                value={item.cluster}
                controlShouldRenderValue={false}
                theme={selectThemeColors}
                className="react-select"
                classNamePrefix="select"
                isLoading={item.clusterLoading}
                placeholder={intl.formatMessage({
                  id: item.clusterLoading ? "Loading..." : "Select",
                  defaultMessage: item.clusterLoading ? "Loading..." : "Select",
                })}
                onChange={(e) => {
                  item.setAttribute("cluster", e);
                  item.setAttribute("site", null);
                  item.getAvailableSites()
                }}
                options={item.clusters?.map((item) => {
                  return {
                    ...item,
                    label: getTranslation(item.name_ar || item.name),
                  };
                })}
                isDisabled={
                  !item?.form_name?.name || !item.start_date || !item.end_date
                }
              />
              {!!item?.cluster?.length && (
                <Row
                  className="gap-1"
                  style={{ marginInline: 1, marginTop: 10 }}
                >
                  {item?.cluster?.map((cluster) => (
                    <Col
                      className="selected_options col-auto"
                      key={cluster._id}
                    >
                      <div className="text">{getTranslation(cluster.name)}</div>
                      <div
                        className="cross"
                        onClick={() => {
                          item.setAttribute("site", null);
                          filterOptions(
                            item.cluster,
                            cluster._id,
                            "cluster",
                            "getAvailableSites"
                          );
                        }}
                      >
                        {SmallCrossIocn}
                      </div>
                    </Col>
                  ))}
                </Row>
              )}
              {/* <ValidationHandler isValid={item?.cluster?.length} /> */}
            </Col>{" "}
            <Col className="mt-1" md="6" sm="12">
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <Label className="form_label">
                  <FormattedMessage id="Sites" defaultMessage={"Sites"} />
                </Label>
                <div
                  style={{ cursor: "pointer", display: "flex" }}
                  onClick={() => {
                    if (item?.cluster?.length) {
                      item.setAttribute("site", item.sites)
                    }
                  }}
                >
                  <span style={{ marginRight: 4, marginTop: -3 }}>
                    {CircleWithPlusIcon}
                  </span>
                  <div className="add_all_sites">
                    Click here to add all sites
                  </div>
                </div>
              </div>

              <Select
                isMulti
                name="sites"
                value={item.site}
                closeMenuOnSelect={false}
                controlShouldRenderValue={false}
                theme={selectThemeColors}
                className="react-select"
                classNamePrefix="select"
                isLoading={item.siteLoading}
                placeholder={intl.formatMessage({
                  id: item.siteLoading ? "Loading..." : "Select",
                  defaultMessage: item.siteLoading ? "Loading..." : "Select",
                })}
                onChange={(e) => {
                  item.setAttribute("site", e);
                }}
                onInputChange={(inputValue, { action }) => {
                  if (action === "input-change") {
                    item.onSearch(inputValue);
                  }
                }}
                options={item.sites?.map((item) => {
                  return {
                    ...item,
                    label: getTranslation(item.name_ar || item.name),
                  };
                })}
                isDisabled={
                  !item?.form_name?.name || !item.start_date || !item.end_date || !item?.cluster?.length
                }
                menuPlacement="top"
              />
              {!!item?.site?.length && (
                <Row
                  className="gap-1"
                  style={{ marginInline: 1, marginTop: 10 }}
                >
                  {item?.site?.map((site, ind) => {
                    if (ind < 3) {
                      return (
                        <Col
                          className="selected_options col-auto"
                          key={site._id}
                        >
                          <div className="text">
                            {getTranslation(site.name_ar || site.name)}
                          </div>
                          <div
                            className="cross"
                            onClick={() =>
                              filterOptions(item.site, site._id, "site")
                            }
                          >
                            {SmallCrossIocn}
                          </div>
                        </Col>
                      );
                    }
                  })}
                  {item?.site?.length > 3 && (
                    <>
                      <Col
                        className="selected_options col-auto"
                        onClick={toggleModal}
                      >
                        {item.isAllSitesSelected ? (
                          <div className="text">
                            {" "}
                            +{item.totalSites - 3} Sites{" "}
                          </div>
                        ) : (
                          <div className="text">
                            {" "}
                            +{item.site.length - 3} Sites{" "}
                          </div>
                        )}
                      </Col>
                      <SitesOptionsModel
                        item={item}
                        modal={modal}
                        toggle={toggleModal}
                      />
                    </>
                  )}
                </Row>
              )}
            </Col>{" "}
          </Row>
        </Card>
      )}

      <Card className="shadow-none mt-2 auto_save">
        <CardBody>
          <Row>
            <Col sm="12">
              {item.isReview ? (
                <div className="d-flex flex-row-reverse">
                  <Submit />
                  <Button.Ripple
                    className="round btn mx-3 button_style"
                    onClick={(e) => {
                      e.preventDefault();
                      item.setIsReview(false);
                    }}
                    color="primary"
                  >
                    <span style={{ color: "white" }}>
                      {ChevronLeftWhite}
                     <span style={{marginRight: 12}}><FormattedMessage id="Back" defaultMessage={"Back"} /> </span>
                    </span>
                  </Button.Ripple>
                </div>
              ) : (
                <div className="d-flex justify-content-between align-items-center flex-wrap">
                  {/* <AutoSave draft={item?.draft} /> */}
                  <div>
                    {
                      !item.siteAvailablityStatus && (
                        <div style={{color: 'red'}}>
                          {item.siteAvailablityMsg}
                        </div>
                      )
                    }
                  </div>
                  <div>
                    <Button.Ripple
                      className="round btn mx-3 button_style"
                      onClick={(e) => {
                        e.preventDefault();
                        navigate("/Schedule-Self-Assessment");
                      }}
                      color="primary"
                      outline
                    >
                      <span style={{marginRight: 10}}>
                        <span>{ChevronLeftGreen}</span>
                        <FormattedMessage id="Cancel" defaultMessage="Cancel" />
                      </span>
                    </Button.Ripple>
                    <Button.Ripple
                      className="round btn button_style"
                      onClick={(e) => {
                        e.preventDefault();
                        item.review();
                      }}
                      color="primary"
                      disabled={!item.siteAvailablityStatus}
                    >
                      <span style={{ color: "white", marginLeft: 10}}>
                        <FormattedMessage
                          id={"Review"}
                          defaultMessage={"Review"}
                        />
                        <span>{ChevronRightWhite}</span>
                      </span>
                    </Button.Ripple>
                  </div>
                </div>
              )}
            </Col>
          </Row>
        </CardBody>
      </Card>
    </Fragment>
  );
});

export default ScheduleSelfAssessment;
