import { Col, Row } from 'antd';
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import React, { Fragment } from "react";
import './styles.scss'

const JournalModal = ({title, data, modal, toggle, modalLoading }) => {

  const toggleClose = () => toggle()

  return (
    <Fragment>
      <Modal centered className="modal_main" isOpen={modal} toggle={toggleClose} unmountOnClose={true}>
        <ModalHeader toggle={toggleClose}>{title}</ModalHeader>
        <ModalBody>
          <div className="modal_body">
          {
            modalLoading ? (
              <div className="text-center my-5">
                <div className="spinner-border text-primary" role="status">
                  <span className="sr-only"></span>
                </div>
              </div>
            ) : (
              <Fragment>
                <Row className='gap-1 names_row'>
                  {
                    data?.map((item, i) => (
                      <Col key={i} className="col-auto">
                        <div className="names">{item.name}</div>
                      </Col>
                    ))
                  }
                </Row>
              </Fragment>
              )
            }
          </div>
        </ModalBody>
      </Modal>
    </Fragment>
  );
};

export default JournalModal
