export const unProtectedRoutes = [
  "login",
  "forgot-password",
  "reset-password",
  "check-reset",
  "institute-register",
  "institute-set-password",
  "TI",
  "form",
];
