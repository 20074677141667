import { Fragment, useEffect, useState } from "react";
import {
  Row,
  Col,
  Modal,
  ModalBody,
  Button,
  Label,
  Input,
  FormFeedback,
  ModalHeader,
  FormGroup,
  Spinner,
  Card,
} from "reactstrap";
import List from "../components/listing/List";
import Dropdown from "../components/Dropdown";
import FormService from "../services/FormService";
import get from "lodash/get";
import startCase from "lodash/startCase";
import FlatPicker from "react-flatpickr";

import moment from "moment";
import "./style.scss";
import toast from "react-hot-toast";
import { MoreVertical, Plus } from "react-feather";
import { useNavigate } from "react-router-dom";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
const MySwal = withReactContent(Swal);
import { getUserData } from "@utils";
import ListHeader from "../components/ListHeader";
import { FormattedMessage } from "react-intl";
import { observer } from "mobx-react";
import { FormBuilderModel } from "../models/FormBuilderModel";
import FileUploader from "../components/FileUpload/FileUploader";
import { useRef } from "react";
import Select from "react-select";
import { selectThemeColors } from "@utils";
import { IntlService } from "../../wasfaty/services";
import Templates from "./Templates";
import { Tabs, Collapse, Switch } from "antd";
import { FacilityModel } from "../../wasfaty/Models/FacilityModel";
import SyncStandards from "./SyncStandards";
const facilityModel = new FacilityModel();
import CopyToClipboard from "react-copy-to-clipboard";

export const appStatusProps = {
  ACTIVE: {
    color: "green",
    label: "Active",
    count: 0,
  },
  INACTIVE: {
    color: "brown",
    label: "In Active",
    count: 0,
  },
};

const ListComponent = observer(() => {
  const navigate = useNavigate();
  const [selectedRow, setSelectedRow] = useState();

  const user = getUserData();
  const type = user.type?.toLowerCase();
  const userId = user._id;
  const canEditDelete = ["admin"].includes(type);
  const canView = ["moderator"].includes(type);

  const [statusTabs, setStatusTabs] = useState(appStatusProps);

  const [activeStatusTab, setActiveStatusTab] = useState("ACTIVE");
  const activeStatusTabRef = useRef();
  activeStatusTabRef.current = activeStatusTab;

  const [items, setItems] = useState([]);
  const [templates, settemplates] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const [formType, setFormType] = useState("All");
  const formTypeRef = useRef();
  formTypeRef.current = formType;

  const isCampaign = formTypeRef.current === "Scheduled";

  const [pagination, setpagination] = useState({
    page: 0,
    pageCount: 0,
    perPage: 10,
    to: 0,
    totalPages: 0,
  });

  const [filter, setFilter] = useState({
    search: "",
    fromDate: "",
    toDate: "",
  });
  const [loading, setLoading] = useState(false);

  const [createModalStates, setCreateModalstates] = useState(
    () => new FormBuilderModel()
  );

  const getAllItems = (query = { selected: 0 }, isSamePage = false) => {
    setIsLoading(true);
    setLoading(true);

    const params = {
      page: isSamePage ? pagination.page : query.selected + 1,
      per_page: query?.perPage || 10,
      form_status: activeStatusTabRef.current === "ACTIVE",
      is_scheduled: formTypeRef.current === "Scheduled",
      ...filter,
    };

    FormService.find(params)
      .then((res) => {
        if (res?.data?.data) {
          let counts = res.data.resultCount;
          settemplates(res.data.templates);
          if (counts) {
            let newTabs = {};
            Object.entries(statusTabs).forEach(([key, tab]) => {
              tab.count = counts[key];
              newTabs[key] = tab;
            });
            setStatusTabs(newTabs);
          }

          setItems(
            res.data.data.data.map((item) => {
              item.site =
                (item.site?._id ? item.site : item.obligation_site) || {};

              delete item.obligation_site;
              return item;
            })
          );
          const _pagination = res.data.data;
          const page = _pagination.current_page;
          const perPage = _pagination.per_page;
          const totalPages = _pagination.total;
          const pageCount = Math.ceil(totalPages / perPage);

          const to = _pagination.to;

          setpagination({
            page,
            pageCount,
            perPage,
            totalPages: res.data?.resultCount?.ALL || totalPages,
            to,
          });
          setLoading(false);
          setIsLoading(false);
        }
      })
      .catch(() => {
        setLoading(false);
        setIsLoading(false);
      });
  };

  const showData = (e) => {
    navigate(`/${e}/Form-Builder/Add/`);
  };

  const updateStatus = (id, status) => {
    FormService.SC.postCall({
      url: `form/change-status`,
      data: { id, status },
    }).then((res) => {
      if (res.status == 200) {
        setTimeout(() => {
          toast.success(res.data.data);
        }, 100);
        getAllItems({ isFromSearch: false, selected: pagination.page - 1 });
      }
    });
  };

  const DeleteData = (id) => {
    MySwal.fire({
      html: "<p class='confirm-class-head' >Are you sure you want to delete?</p>",
      showCancelButton: true,
      focusConfirm: false,
      confirmButtonText: "Ok",
      cancelButtonText: "Cancel",
      customClass: {
        confirmButton: "btn btn-primary",
        cancelButton: "btn btn-outline-danger ms-1",
      },
      buttonsStyling: false,
    }).then(({ isConfirmed }) => {
      if (isConfirmed) {
        FormService.delete(id).then((res) => {
          if (res.status === 200) {
            getAllItems({
              selected: pagination.page - 1,
            });
            try {
              toast.success(res.data.data.data || res.data.data);
            } catch (error) {
              toast.success("Form deleted successfully!");
            }
          } else {
            if (res.errors) {
              toast.error(error);
            }
          }
        });
      }
    });
  };
  const basicColumns = [
    {
      name: "Name Arabic",
      sortable: true,
      cell: (row) => <span>{get(row, "name", "")}</span>,
    },
    {
      name: "Name English",
      cell: (row) => <span>{get(row, "meta_data.name_en", "N/A")}</span>,
    },

    {
      name: "Start Date",
      disableStartCase: true,
      omit: !isCampaign,
      cell: (row) => (
        <span>
          {moment(row.start_date).format("DD-MM-YYYY")}{" "}
          <span style={{ fontWeight: "bold" }}>
            {moment(row.start_date).format("h:mm")}
          </span>
        </span>
      ),
    },
    {
      name: "End Date",
      disableStartCase: true,
      omit: !isCampaign,
      cell: (row) => (
        <span>
          {moment(row.end_date).format("DD-MM-YYYY")}{" "}
          <span style={{ fontWeight: "bold" }}>
            {moment(row.end_date).format("h:mm")}
          </span>
        </span>
      ),
    },

    {
      name: "Side Menu",
      cell: (row) => (
        <span>{startCase(get(row, "meta_data.menuName")) || "Forms"}</span>
      ),
    },
    {
      name: "Logo",
      cell: (row) =>
        row.icon && (
          <img src={row.saved_icon || row.icon} width={50} height={50} />
        ),
    },

    {
      name: "Creation Date",
      disableStartCase: true,
      cell: (row) => (
        <span>
          {moment(row.created_at).format("DD-MM-YYYY")}{" "}
          <span style={{ fontWeight: "bold" }}>
            {moment(row.created_at).format("h:mm")}
          </span>
        </span>
      ),
    },
    {
      name: "Status",
      header: (props) => (
        <th style={{ textAlign: "center" }}>{props.children}</th>
      ),
      cell: (row) => {
        return (
          <FormGroup switch>
            <Input
              style={{ cursor: "pointer" }}
              type="switch"
              role="switch"
              checked={!!row.status}
              onChange={(e) => updateStatus(row._id, e.target.checked)}
            />
          </FormGroup>
        );
      },
    },

    {
      name: "Public Link",
      width: "300px",
      cell: (row) => {
        return (
          <CopyToClipboard
            onCopy={() => {
              toast.success("Link Copied!");
            }}
            text={`${window.location.origin}/form/${row?.slug}`}
          >
            <Button color="primary">Copy</Button>
          </CopyToClipboard>
        );
      },
    },

    {
      name: "ACTION",
      sortable: false,
      cell: (row) => (
        <>
          {(canEditDelete ||
            canEditDelete ||
            canView ||
            row?.user?._id === userId) && (
            <MoreVertical
              style={{ marginLeft: 10, cursor: "pointer" }}
              onClick={(e) => {
                setSelectedRow({
                  ...row,
                  clientX: e.clientX,
                  clientY: e.clientY,
                });
              }}
            />
          )}
        </>
      ),
    },
  ];

  const ValidationHandler = observer(
    ({ isValid, message = `"This field is required!"` }) => {
      return (
        <>
          <Input
            type="text"
            hidden
            invalid={!isValid && createModalStates.createValidation}
          />

          <FormFeedback>
            <FormattedMessage id={message} defaultMessage={message} />
          </FormFeedback>
        </>
      );
    }
  );
  const TextInput = observer(
    ({ attribute, title, md = "6", value, onChange, isRequired = true }) => {
      return (
        <Col className="mb-1" md={md} sm="12">
          <Label className="form-label">
            <FormattedMessage id={title} defaultMessage={title} />{" "}
            {isRequired ? " *" : ""}
          </Label>
          <Input
            type="text"
            value={value}
            onChange={(e) => onChange(attribute, e.target.value)}
          />

          {isRequired && (
            <ValidationHandler isValid={createModalStates[attribute]} />
          )}
        </Col>
      );
    }
  );
  const fileRef = useRef();

  const FileUploaderWrapper = observer(({ isSm = false, attribute }) => {
    const fileHandler = createModalStates[attribute];
    if (!fileHandler) return null;
    return (
      <div>
        <Label className="form-label" for="EmailMulti">
          <FormattedMessage id="Attachment" defaultMessage={"Attachment "} />
        </Label>
        <FileUploader
          // isFileLimit={1}
          maxFiles={1}
          isAllFiles={false}
          width={20}
          isSm={isSm}
          images={fileHandler.images}
          ref={fileHandler.fileRef}
          onUploadingComplete={() => {
            fileHandler.setIsUploading(
              fileRef.current?.checkIsAllFilesUploaded?.()
            );
          }}
          onUploadingStart={() => {
            fileHandler.setIsUploading(false);
          }}
          handleChange={(images) => fileHandler.setImages(images)}
        />
        <ValidationHandler isValid={fileHandler.images.length} />
      </div>
    );
  });

  const FormModal = observer(() => {
    return (
      <ModalBody className="d-flex flex-column">
        <Row>
          <TextInput
            title="Name Arabic"
            attribute="formName"
            value={createModalStates.formName}
            md="12"
            onChange={createModalStates.handleChange}
            isValid={createModalStates.createValidation}
          />
          <TextInput
            title="Name English"
            attribute="name_en"
            value={createModalStates?.meta_data?.name_en}
            md="12"
            onChange={createModalStates.handleChangeMeta}
            isRequired={false}
          />
          {!createModalStates.isEdit && (
            <Col className="mb-1" md="12" sm="12">
              <Label className="form-label">
                <FormattedMessage
                  id="Select Template"
                  defaultMessage={"Select Template"}
                />
              </Label>
              <Select
                value={createModalStates?.meta_data?.template}
                onChange={(e) =>
                  createModalStates.handleChangeMeta("template", e)
                }
                className="react-select"
                classNamePrefix="select"
                theme={selectThemeColors}
                options={templates.map((t) => ({
                  label: t.name,
                  value: t._id,
                }))}
                isClearable={true}
                placeholder={"Select"}
              />
            </Col>
          )}
          <div className="d-flex flex-column mt-1 mb-2">
            <Label for="switch-success" className="form-check-label mb-50">
              <FormattedMessage
                id="Is Campaign?"
                defaultMessage={"Is Campaign?"}
              />
            </Label>
            <div className="form-switch form-check-success">
              <Input
                type="switch"
                id="switch-success"
                name="success"
                checked={createModalStates?.is_campaign}
                onChange={(e) => {
                  createModalStates.handleChange(
                    "is_campaign",
                    e.target.checked
                  );
                }}
              />
            </div>
          </div>
          {createModalStates?.is_campaign && (
            <Collapse
              bordered={false}
              style={{ marginBottom: 10 }}
              defaultActiveKey={["1"]}
              items={[
                {
                  key: "1",
                  label: IntlService.m("Fill Campaign Details"),
                  children: (
                    <div className="mb-2">
                      <TextInput
                        title="Campaign Title"
                        attribute="campaign_title"
                        value={createModalStates?.campaign_title}
                        md="12"
                        onChange={createModalStates.handleChange}
                        isRequired={true}
                      />

                      <Col className="mb-1" md={"12"} sm="12">
                        <Label className="form-label">
                          <FormattedMessage
                            id={"Start Date"}
                            defaultMessage={"Start Date"}
                          />{" "}
                          *
                        </Label>

                        <FlatPicker
                          data-enable-time
                          // options={{
                          //   minDate: today,
                          // }}
                          value={createModalStates?.start_date}
                          onChange={(e) => {
                            createModalStates.handleChange(
                              "start_date",
                              new Date(e)
                            );
                            createModalStates.handleChange("end_date", null);
                          }}
                          className="form-control button-filter"
                          style={{ width: "100", background: "white" }}
                        />
                        <ValidationHandler
                          isValid={createModalStates?.start_date}
                        />
                      </Col>
                      <Col className="mb-1" md={"12"} sm="12">
                        <Label className="form-label">
                          <FormattedMessage
                            id={"End Date"}
                            defaultMessage={"End Date"}
                          />{" "}
                          *
                        </Label>

                        <FlatPicker
                          data-enable-time
                          options={{
                            minDate: createModalStates?.start_date,
                          }}
                          value={createModalStates?.end_date}
                          onChange={(e) => {
                            createModalStates.handleChange(
                              "end_date",
                              new Date(e)
                            );
                          }}
                          placeholderText="Select date"
                          className="form-control button-filter"
                          style={{ width: "100", background: "white" }}
                        />
                        <ValidationHandler
                          isValid={createModalStates?.end_date}
                        />
                      </Col>
                    </div>
                  ),
                },
              ]}
            />
          )}

          <Collapse
            bordered={false}
            items={[
              {
                key: "1",
                label: IntlService.m("Permissions (optional)"),
                children: (
                  <div className="mb-2">
                    <TextInput
                      title="Menu Name"
                      attribute="menuName"
                      value={createModalStates?.meta_data?.menuName}
                      md="12"
                      onChange={createModalStates.handleChangeMeta}
                      isRequired={false}
                    />
                    <Col className="mb-1" md="12" sm="12">
                      <Label className="form-label">
                        <FormattedMessage
                          id="For Role"
                          defaultMessage={"For Role"}
                        />
                      </Label>
                      <Select
                        isMulti
                        name="Roles"
                        value={createModalStates?.meta_data?.roles?.map?.(
                          (value) => ({
                            value,
                            label: startCase(value),
                          })
                        )}
                        onChange={(e) =>
                          createModalStates.handleChangeMeta(
                            "roles",
                            e.map(({ value }) => value)
                          )
                        }
                        className="react-select"
                        classNamePrefix="select"
                        theme={selectThemeColors}
                        options={[
                          {
                            id: 2,
                            value: "regional representatives",
                            label: "Regional Representatives",
                          },
                          {
                            id: 3,
                            value: "supervisor",
                            label: "Supervisor",
                          },
                          { id: 4, value: "agent", label: "Inspector" },
                          { id: 5, value: "pharmacist", label: "Pharmacist" },
                          { id: 5, value: "facility", label: "Facility" },
                          {
                            id: 7,
                            value: "cluster manager",
                            label: "Cluster Manager",
                          },
                          { id: 8, value: "expert", label: "Expert" },
                          {
                            id: 9,
                            value: "facility manager",
                            label: "Facility Manager",
                          },
                          {
                            id: 10,
                            value: "regulator representative",
                            label: "Regulator Representative",
                          },
                        ]}
                        isClearable={true}
                        placeholder={"Select"}
                        closeMenuOnSelect={false}
                      />
                    </Col>
                    <Col className="mb-1" md="12" sm="12">
                      <Label className="form-label">
                        <FormattedMessage
                          id="For Region"
                          defaultMessage={"For Region"}
                        />
                      </Label>
                      <Select
                        isMulti
                        name="regions"
                        value={createModalStates?.meta_data?.regions}
                        onChange={(e) =>
                          createModalStates.handleChangeMeta("regions", e)
                        }
                        className="react-select"
                        classNamePrefix="select"
                        theme={selectThemeColors}
                        options={createModalStates.regionOptions}
                        isClearable={true}
                        placeholder={"Select"}
                        closeMenuOnSelect={false}
                      />
                    </Col>
                    <Col className="mb-1" md="12" sm="12">
                      <Label className="form-label">
                        <FormattedMessage
                          id="Facility Types"
                          defaultMessage={"Facility Types"}
                        />
                      </Label>
                      <Select
                        isMulti
                        value={createModalStates?.meta_data?.facility_types?.map?.(
                          (value) => ({
                            value,
                            label: value,
                          })
                        )}
                        onChange={(e) =>
                          createModalStates.handleChangeMeta(
                            "facility_types",
                            e.map(({ value }) => value)
                          )
                        }
                        className="react-select"
                        classNamePrefix="select"
                        theme={selectThemeColors}
                        options={facilityModel.options.map((label) => {
                          return {
                            label,
                            value: label,
                          };
                        })}
                        isClearable={true}
                        placeholder={"Select"}
                        closeMenuOnSelect={false}
                      />
                    </Col>
                  </div>
                ),
              },
            ]}
          />

          <Collapse
            bordered={false}
            items={[
              {
                key: "1",
                label: IntlService.m("Development only (optional)"),
                children: (
                  <div className="mb-2">
                    <TextInput
                      title="Route Name (Dev)"
                      attribute="route"
                      value={createModalStates?.meta_data?.route}
                      md="12"
                      onChange={createModalStates.handleChangeMeta}
                      isRequired={false}
                    />

                    <div className="d-flex flex-column mt-1 mb-2">
                      <Label
                        for="switch-success"
                        className="form-check-label mb-50"
                      >
                        <FormattedMessage
                          id="Is Dynamic Excel?"
                          defaultMessage={"Is Dynamic Excel?"}
                        />
                      </Label>
                      <div className="form-switch form-check-success">
                        <Input
                          type="switch"
                          id="switch-success"
                          name="success"
                          checked={createModalStates?.meta_data?.dynamic_excel}
                          onChange={(e) => {
                            createModalStates.handleChangeMeta(
                              "dynamic_excel",
                              e.target.checked
                            );
                          }}
                        />
                      </div>
                    </div>
                    <TextInput
                      title="Columns(Dev separated by comma)"
                      attribute="columns"
                      value={createModalStates?.meta_data?.columns}
                      md="12"
                      onChange={createModalStates.handleChangeMeta}
                      isRequired={false}
                    />
                    <TextInput
                      title="Custom Public Success Message"
                      attribute="custom_message"
                      value={createModalStates?.meta_data?.custom_message}
                      md="12"
                      onChange={createModalStates.handleChangeMeta}
                      isRequired={false}
                    />
                  </div>
                ),
              },
            ]}
          />
          <FileUploaderWrapper
            attribute="icon"
            md="12"
            isValid={createModalStates.createValidation}
          />
        </Row>
        <Button
          disabled={createModalStates.isUpdating}
          onClick={() =>
            createModalStates.validateCreate()
              ? createModalStates.submitCreate(null, () =>
                  getAllItems({}, true)
                )
              : null
          }
          color="primary"
        >
          <FormattedMessage
            id={createModalStates.isEdit ? "Update" : "Create"}
            defaultMessage={createModalStates.isEdit ? "Update" : "Create"}
          />
          &nbsp;
          {createModalStates.isUpdating && <Spinner size={"sm"} />}
        </Button>
      </ModalBody>
    );
  });

  const toggleStatusTabs = (key) => {
    setActiveStatusTab(key);
    setItems([]);
    setTimeout(() => {
      getAllItems({ isFromSearch: true, selected: 0 });
    }, 100);
  };

  return (
    <Fragment>
      <Modal
        unmountOnClose={true}
        isOpen={createModalStates.modalShow}
        toggle={() => {
          let a = new FormBuilderModel();
          setCreateModalstates(a);
        }}
      >
        <ModalHeader>
          <h3>Create a Form </h3>
        </ModalHeader>
        <FormModal />
      </Modal>

      {!!selectedRow && (
        <Dropdown
          size={20}
          row={selectedRow}
          DeleteData={DeleteData}
          showData={showData}
          editTitle="Edit"
          detailsText="Open Form"
          isEdit={true}
          isDelete={canEditDelete}
          onEdit={(id) => createModalStates.onEdit(selectedRow)}
          openEngin={(id) => createModalStates.openEngine(id)}
          onClose={() => setSelectedRow(null)}
        />
      )}
      <ListHeader
        totalHeader="Total Forms"
        pagination={pagination}
        addButtonText="Create Form"
        getData={getAllItems}
        loading={loading}
        addButton={
          <>
            <Button.Ripple
              outline
              onClick={() => {
                let a = new FormBuilderModel();
                setCreateModalstates(a);
                a.toggleModel();
              }}
              className="add-form-button waves-effect round btun   btn btn-primary my-1"
            >
              <p className="">
                <FormattedMessage
                  defaultMessage={"Create Form"}
                  id={"Create Form"}
                />
              </p>
              <Plus size={18} />
            </Button.Ripple>

            <Templates
              templates={templates}
              callback={getAllItems}
              deleteTemplate={DeleteData}
            />
            <SyncStandards />
          </>
        }
      />

      <Tabs
        style={{
          background: "white",
        }}
        tabPosition={"left"}
        onTabClick={(tab) => {
          console.log(tab);
          setItems([]);
          formTypeRef.current = tab === 0 ? "" : "Scheduled";
          setFormType(formTypeRef.current);
          getAllItems();
        }}
        items={[
          {
            label: `All`,
            key: 0,
            children: (
              <>
                <Card className="requests-list-STCS shadow-none">
                  <div className="requests-list-STCS--tabsContainer">
                    {Object.entries(statusTabs).map(([key, value], index) => {
                      return (
                        <button
                          key={index}
                          className={`requests-list-STCS--tabsContainer__tab ${
                            activeStatusTab === key ? "active" : ""
                          } ${key}-tab`}
                          onClick={() => toggleStatusTabs(key)}
                        >
                          <span className="requests-list-STCS--tabsContainer__tab--text">
                            {IntlService.m(value.label)}
                          </span>
                          <span className="requests-list-STCS--tabsContainer__tab--count">
                            {value?.count || 0}
                          </span>
                        </button>
                      );
                    })}
                  </div>
                </Card>
                <List
                  basicColumns={basicColumns}
                  Mock={items}
                  pagination={pagination}
                  handlePageChange={getAllItems}
                  isLoading={isLoading}
                />
              </>
            ),
          },
          {
            label: `Campaigns`,
            key: 1,
            children: (
              <>
                <Card className="requests-list-STCS shadow-none">
                  <div className="requests-list-STCS--tabsContainer">
                    {Object.entries(statusTabs).map(([key, value], index) => {
                      return (
                        <button
                          key={index}
                          className={`requests-list-STCS--tabsContainer__tab ${
                            activeStatusTab === key ? "active" : ""
                          } ${key}-tab`}
                          onClick={() => toggleStatusTabs(key)}
                        >
                          <span className="requests-list-STCS--tabsContainer__tab--text">
                            {IntlService.m(value.label)}
                          </span>
                          <span className="requests-list-STCS--tabsContainer__tab--count">
                            {value?.count || 0}
                          </span>
                        </button>
                      );
                    })}
                  </div>
                </Card>
                <List
                  basicColumns={basicColumns}
                  Mock={items}
                  pagination={pagination}
                  handlePageChange={getAllItems}
                  isLoading={isLoading}
                />
              </>
            ),
          },
        ]}
      />
    </Fragment>
  );
});

export default ListComponent;
