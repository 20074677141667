import React from "react";
import { Card } from "reactstrap";

import {
  ArrowTopRightDarkIcon,
  ExclamationRedIcon,
  LocationIcon,
  NoOfSitesGreenImage,
  NoOfSitesWhiteImage,
} from "../../../../../assets/wasfaty/SVG";
import { IntlService } from "../../../../wasfaty/services";

const SitesAndComplaintCard = ({ sitesSubmission }) => {
  return (
    <Card className="number_card">
      <div className="d-flex justify-content-between align-items-center">
        <div className="no_of_sites">
          <div className="arrow">{ArrowTopRightDarkIcon}</div>
          <div className="dashboard_content">
            <div>{LocationIcon}</div>
            <div className="sites">{IntlService.m("NO OF SITES")}</div>
            <div className="count">{sitesSubmission?.totalSites}</div>
          </div>
          {NoOfSitesWhiteImage}
        </div>
        <div className="no_of_complaint">
          <div className="arrow">{ArrowTopRightDarkIcon}</div>
          <div className="dashboard_content">
            <div>{ExclamationRedIcon}</div>
            <div className="complaints">{IntlService.m("NO OF Complaint")}</div>
            <div className="count">{sitesSubmission?.totalComplySites}</div>
          </div>
          {NoOfSitesGreenImage}
        </div>
      </div>
    </Card>
  );
};

export default SitesAndComplaintCard;
