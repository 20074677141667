import React, { Fragment } from "react";
import { Card, Row } from "reactstrap";
import { IntlService } from "../../../../wasfaty/services";

const CountsTableCard = ({ standeredSubmission1, standeredSubmission2 }) => {
  return (
    <Card className="table_card">
      <div className="d-flex flex-wrap justify-content-between align-items-center">
        {standeredSubmission1?.map((data, index) => (
          <Fragment key={index}>
            <div className="table_item">
              <div className="count">{data.count}</div>
              <div style={{ marginTop: -5, marginBottom: 5 }}>{data.icon}</div>
              <div className="name">{IntlService.m(data.name)}</div>
            </div>
            {index < standeredSubmission1?.length - 1 && (
              <div className="v_row" />
            )}
          </Fragment>
        ))}
      </div>

      <div className="rows_card">
        <Row className="gy-1 gx-0">
          {standeredSubmission2?.map((item, index) => (
            <Fragment key={index}>
              <div className="d-flex justify-content-between align-items-center card_item">
                <div className="d-flex justify-content-between align-items-center">
                  <div className="icon">{item.icon}</div>
                  <div className="name">{IntlService.m(item.name)}</div>
                </div>
                <div className="count">{item.count}</div>
              </div>
              {index < standeredSubmission2?.length - 1 && (
                <div className="h_row" />
              )}
            </Fragment>
          ))}
        </Row>
      </div>
    </Card>
  );
};

export default CountsTableCard;
