import moment from "moment";
import ExcelServiceDynamic from "./ExcelServiceDynamic";

const { Schema } = require("@formily/react");
const XLSX = require("xlsx");

class ExcelService {
  allHeaders = [];
  extractedData = [];
  data = [];
  schema = {};

  sortId = 0;

  stats = [];
  totals = [];
  form = {};
  isOtherExcel = false;

  constructor(form_id, schema, data, form) {
    if (form?.meta_data?.dynamic_excel) {
      this.isOtherExcel = true;
      return new ExcelServiceDynamic(schema, data, form).generateExcel();
    }

    this.schema = schema;
    this.data = data;
    this.form = form;
    if (form_id == process.env.REACT_APP_MEDICINE_AVAILIBILITY_FORM) {
      this.isOtherExcel = new ExcelServiceMedicine(form_id, schema, data, form);
      return true;
    }

    this.extractedData = data
      .map(({ data, user, report_no, inspection_type, created_at }, index) => {
        return Object.entries(data)
          .filter(([keuy, value]) => Array.isArray(value) && value?.[0]?.step)
          .map(([key, value]) => value)
          .flat()
          .map((question) => {
            let otherCols = {};
            if (form_id === "66404a888fc238b1660689cc") {
              otherCols["Country"] = data?.country?.label;
              otherCols["Branch"] = data?.branch_number?.value;
              otherCols["Mission Name"] = data?.["0zy0ynd4w0h"];
            }
          
            let resp = {
              ["#No"]: index + 1,
              ["Report No"]: report_no,
              ["Region"]: data?.region?.label,
              ["Cluster"]: data?.cluster?.label,
              ...otherCols,
              ["Site"]: data?.site?.label,
              ["Inspection Type"]: inspection_type,
              ["Step Name"]: question.step,
              ["Question Number"]: question.no,
              ["Standard"]: question.title,
              ["Risk"]: "",
              ["Answer"]: question.question,
              ["User Name"]: user?.name,
              ["User Email"]: user?.email,
              ["Submission Date"]: moment(created_at).format("ll"),
            };

            const riskRating = question.Likelihood * question.Impact;
            if (riskRating >= 0 && riskRating <= 2) {
              resp["Risk"] = "Minor";
            } else if (riskRating >= 3 && riskRating <= 9) {
              resp["Risk"] = "Moderate";
            } else if (riskRating >= 10 && riskRating <= 14) {
              resp["Risk"] = "Major";
            } else if (riskRating > 14) {
              resp["Risk"] = "Catastrophic";
            }

            if (form_id === "66404a888fc238b1660689cc") {
              delete resp["Site"];
            }

            return resp;
          });
      })
      .flat();

    const regionCounts = {};
    let complyCount = 0; // Initialize count for Answer = "Comply"
    let notComplyCount = 0; // Initialize count for Answer = "Comply"
    let naCount = 0; // Initialize count for Answer = "Comply"

    this.extractedData.forEach((item) => {
      const region = item["Region"];
      const answer = item["Answer"];

      // Count unique regions
      if (!regionCounts[region]) {
        regionCounts[region] = {
          users: new Set(),
          comply: 0,
          notComply: 0,
          na: 0,
        };
      }

      regionCounts[region].users.add(item["User Name"]);

      // Increment counts based on answer
      if (answer === "Comply") {
        regionCounts[region].comply++;
        complyCount++;
      } else if (answer === "Not Comply") {
        regionCounts[region].notComply++;
        notComplyCount++;
      } else if (answer === "N/A") {
        regionCounts[region].na++;
        naCount++;
      }
    });

    this.stats = Object.entries(regionCounts).map(([region, value]) => {
      return {
        Region: region,
        "Total Users": value.users.size,
        Comply: value.comply,
        "Not Comply": value.notComply,
        "Not Applicable": value.na,
      };
    });

    let totalInspections = this.data.filter(
      ({ inspection_type }) => inspection_type !== "Self assessment"
    ).length;

    this.totals = [
      {
        ["Total Submissions"]: this.data.length,
        ["Total Inspections"]: totalInspections,

        ["Total Self Assessments"]: this.data.length - totalInspections,
      },
    ];

    console.log(this.schema);
  }

  get uniqueHeaders() {
    return this.allHeaders
      .filter(
        (obj, index, array) =>
          index === array.findIndex((item) => item.header === obj.header)
      )
      .sort((a, b) => a.sortId - b.sortId)
      .map((item) => item.header.replaceAll("\n", ""));
  }

  // Generate Excel file
  generateExcel() {
    if (this.isOtherExcel) return null;

    // Define chunk size, e.g., 50,000 rows per chunk
    const chunkSize = 50000;

    // Helper function to split data into chunks
    const chunkArray = (array, chunkSize) => {
      const chunks = [];
      for (let i = 0; i < array.length; i += chunkSize) {
        chunks.push(array.slice(i, i + chunkSize));
      }
      return chunks;
    };

    // Split extractedData into chunks
    const dataChunks = chunkArray(this.extractedData, chunkSize);

    const workbook = XLSX.utils.book_new();

    dataChunks.forEach((dataChunk, index) => {
      const worksheet = XLSX.utils.json_to_sheet(dataChunk);
      XLSX.utils.book_append_sheet(
        workbook,
        worksheet,
        `Submission Part ${index + 1}`
      );
    });

    // Add stats and totals as separate sheets
    const worksheet2 = XLSX.utils.json_to_sheet(this.stats);
    const worksheet3 = XLSX.utils.json_to_sheet(this.totals);
    XLSX.utils.book_append_sheet(workbook, worksheet2, "Stats");
    XLSX.utils.book_append_sheet(workbook, worksheet3, "Totals");

    // Write the workbook to file
    XLSX.writeFile(
      workbook,
      `${this.form.name} - ${moment().format("llll")}.${"xlsx"}`
    );
  }
}

export default ExcelService;

class ExcelServiceMedicine {
  headerMapping = {
    name: "Medicine Name",
    genericCode: "Generic Code",
    type: "Medicine Type",
    version: "Version",
    medicine_quantity: "Medicine Quantity",
    slug: "Slug",
    updated_at: "Last Updated",
    created_at: "Created At",
    serial_number: "Serial Number",
    label: "Label",
    value: "Value",
    medicine_exist: "Medicine Available",
    is_the_medicine_avilable: "Is Medicine Available",
    quanitity_available: "Quantity Available",
    commnets: "Comments",
  };

  constructor(form_id, schema, data, form) {
    this.schema = schema;
    this.data = data;

    this.data = this.data
      .map(({ data, user, report_no, inspection_type, created_at }, index) => {
        let sub = [];
        let id = index + 1;
        if (data?.general?.length) {
          data.general.forEach((med) => {
            let tmpMed = {
              No: id,
              "Report No": report_no,
              Region: data.region?.label,
              Cluster: data.cluster?.label,
              Site: data.site?.label,
              City: data.city,
              "Medicine Name": med.name,
              "Generic Code": med.genericCode,
              "Medicine Type": med.type,
              "Medicine Available":
                med.medicine_exist || med.is_the_medicine_avilable || "No",
              "Is the medicine quantity available for 1 month?":
                med.is_the_medicine_avilable,
              "Quantity Available": med.quanitity_available,
              "Pharmacy Voilated": med.pharmacy_voilated,
              "Not Voilated Reason": med.not_voilated_reason,
              "Voilated Comment": med.voilated_comment,
              "Voilated Date": med.voilated_date,
              "Submission Date": moment(created_at).format("lll"),
              "Submitted By": user?.name,
            };

            sub.push(tmpMed);
          });
        }

        if (data?.respiratory?.length) {
          data.respiratory.forEach((med) => {
            let tmpMed = {
              No: id,
              "Report No": report_no,
              Region: data.region?.label,
              Cluster: data.cluster?.label,
              Site: data.site?.label,
              City: data.city,
              "Medicine Name": med?.name?.label || med?.name,
              "Medicine Type": "Respiratory Medicines",
              "Medicine Available":
                med.medicine_exist || med.is_the_medicine_avilable || "No",
              "Is the medicine quantity available for 1 month?":
                med.is_the_medicine_avilable,
              "Quantity Available": med.quanitity_available,
              "Pharmacy Voilated": med.pharmacy_voilated,
              "Not Voilated Reason": med.not_voilated_reason,
              "Voilated Comment": med.voilated_comment,
              "Voilated Date": med.voilated_date,
              "Submission Date": moment(created_at).format("lll"),
              "Submitted By": user?.name,
            };

            sub.push(tmpMed);
          });
        }
        return sub;
      })
      .flat();

    this.generateExcel();
  }

  generateExcel() {
    const worksheet = XLSX.utils.json_to_sheet(this.data);
    const workbook = XLSX.utils.book_new();

    XLSX.utils.book_append_sheet(workbook, worksheet, "Medicines");
    XLSX.writeFile(
      workbook,
      `${"محضر توفر الدواء في الصيدليات "} - ${moment().format(
        "llll"
      )}.${"xlsx"}`
    );
  }
}
